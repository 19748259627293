import { apiDELETE, apiGET, apiPOST, apiPUT } from "../utils/apiHandler";

export const fetchDailyReportPreview = (params = { client: null, date: null }) => apiGET("/dashboard/api/drg/daily_report_preview/", params);

export const updateCampaign = (
  body = {
    campaign_id: null,
    agent: null,
    process: null,
    batch: null,
    product: null,
    language: null,
    contact: null,
  }
) => apiPUT("/dashboard/api/admin-campaign-update/", body);

export const fetchAdminAgentSummary = (params) => apiGET("/dashboard/api/agent-summary/", params);

export const getCustomerPaymentData = () => apiGET("/dashboard/api/update_customer_payment_data/");

export const updateCustomerPaymentData = (body) => apiPOST("/dashboard/api/update_customer_payment_dat/", body);

export const getIvrTemplates = (params = { client_id: null, agent_id: null, category: null, app_id: null, language: null }) =>
  apiGET("/dashboard/api/ivr_template/", params);

export const createIvrTemplate = (
  body = { agent: null, language: null, appid: null, template_message: null, recording_url: null, client: null, category: null }
) => apiPOST("/dashboard/api/ivr_template/", body);

export const updateIvrTemplate = (
  body = { agent: null, language: null, appid: null, template_message: null, recording_url: null, client: null, category: null }
) => apiPOST("/dashboard/api/ivr_template_new/", body);

export const getManualWhatsappTemplates = (params = { client_id: null, category: null, process: null }) =>
  apiGET("/dashboard/api/manual_whatsapp_template/", params);

export const createManualWhatsappTemplate = (body = { template_name: null, client: null, process: null, category: null, message_body: null }) =>
  apiPOST("/dashboard/api/manual_whatsapp_template/", body);

export const updateManualWhatsappTemplate = (body = { id: null, template_name: null, client: null, process: null, category: null, message_body: null }) =>
  apiPUT("/dashboard/api/manual_whatsapp_template/", body);

export const fetchCategories = ({ channel = null }) => apiGET("/dashboard/api/get-category/", { channel });

export const createCampaignWhatsapp = (body) => apiPOST("/scrapping/api/whatsapp_campaign/", body);

export const udpateCampaignWhatsapp = (body) => apiPUT("/scrapping/api/whatsapp_campaign/", body);

export const fetchCampaignWhatsapp = (params) => apiGET("/scrapping/api/whatsapp_campaign/", params);

export const getWhatsappSenderNumbers = (params) => apiGET("/scrapping/api/whatsapp_sender_number/", params);

export const addWhatsappSenderNumbers = (body) => apiPOST("/scrapping/api/whatsapp_sender_number/", body);

export const updateWhatsappSenderNumbers = (body) => apiPUT("/scrapping/api/whatsapp_sender_number/", body);

export const getAllAgents = (params) => apiGET("/dashboard/api/agents", params);

export const createNewCategory = (body) => apiPOST("/dashboard/api/template_category/", body);

export const startStopWhatsappCampaign = (body) => apiPOST("/dashboard/api/hit_whatsapp_campaign_status_api/", body);

export const getSenderEmailIDs = (params) => apiGET("/dashboard/api/sender_email_data/", params);

export const addSenderEmailIDs = (body) => apiPOST("/dashboard/api/sender_email_data/", body);

export const getCcBccEmailIDs = (params) => apiGET("/dashboard/api/cc_bcc_email_data/", params);

export const addCcBccEmailIDs = (body) => apiPOST("/dashboard/api/cc_bcc_email_data/", body);

export const stopAllWhatsappCampaign = (body) => apiPOST("/dashboard/api/hit_whatsapp_campaign_task_api/", body);

export const resumeWhatsappCampaign = (body) => apiPOST("/dashboard/api/hit_whatsapp_campaign_resume_api/", body);

export const fetchClientUploadedBatches = (params) => apiGET("/pre_litigation/api/client-batch-upload/", params);

export const createClient = (body) => apiPOST("/client_panel/api/clients/", body);

export const getNoticeMsgTemplateDetails = (body) => apiPOST("/dashboard/api/legal_notice_template_detail/", body);

export const getPrelitigationBulkPdfSqs = (body) => apiPOST("/pre_litigation/api/bulk-pdf-sqs/", body);

export const getClientbatchReport = (body) => apiPOST("/dashboard/api/clint_batch_report", body);

export const getPrelitigationAssignTrackingId = (params) => apiGET("/pre_litigation/api/assign_tracking_id/", params);

export const getPrelitigationOrderStatus = (params) => apiGET("/pre_litigation/api/get-order-status/", params);

export const failedPaymentDataUpload = (body) => apiPOST("/dashboard/api/failed_payment_data_upload/", body);

export const sendLatestPaymentFileRequestMailUpload = (body) => apiPOST("/dashboard/api/send_latest_payment_file_request_mail/", body);

export const customerPaymentDataUpload = (body) => apiPOST("/dashboard/api/update_customer_payment_data/", body);

export const checkBounceEmail = (body) => apiPOST("/awsemail/api/check-bounce-email/", body);

export const getWatiAcccounts = (params) => apiGET("/dashboard/api/wati-account/", params);
export const getMetaAccount = (params) => apiGET("/dashboard/api/meta-account/", params);

export const addWatiAcccount = (body) => apiPOST("/dashboard/api/wati-account/", body);

export const updateWatiAcccount = (body) => apiPUT("/dashboard/api/wati-account/", body);

export const getAxisDeliveryReport = (params) => apiGET("/dashboard/api/axis_delivery_report/", params);

export const generateAuditReport = (params) => apiGET("/dashboard/api/generate_audit_report/", params);

export const getResponseReportSummary = (body) => apiPOST("/dashboard/api/response_report_summary/", body);

export const getLeadReport = (params) => apiGET("/dashboard/api/lead_report/", params);

export const getConciliationReportMail = (params) => apiGET("/dashboard/api/conciliation_report_mail/", params);

export const getAllBatchNoticeReport = (params) => apiGET("/pre_litigation/api/all_batch_notice_report/", params);

export const createMasterCampaign = (body) => apiPOST("/campaigns/api/master_campaign/", body);

export const getMasterCampaign = (params) => apiGET("/campaigns/api/master_campaign/", params);

export const updateMasterCampaign = (body) => apiPOST("/campaigns/api/master_campaign_new/", body);

export const resetMasterCampaignSpeed = (body) => apiPOST("/campaigns/api/reset_master_campaign/", body);

export const testMasterSpeed = (body) => apiPOST("/campaigns/api/test_campaign/", body);

export const getAdminPanelData = (params) => apiGET("/dashboard/api/admin-panel-data/", params);

export const getLeadTypes = (params) => apiGET("/dashboard/api/lead_types/", params);

export const downloadAudioFiles = (body) => apiPOST("/dashboard/api/call_recoding_download", body);

export const updateCallRecords = (body) => apiPOST("/dashboard/api/update_call_records/", body);

export const generateSampleNotice = (params) => apiGET("/pre_litigation/api/check_sample_notice/", params);

export const searchComplaintData = (params) => apiGET("/fir/api/search_complaint_data/", params);

export const distributedLeadsToActiveAgents = (params) => apiGET("/dashboard/api/distributed_leads_to_active_agents/", params);

export const deactivateAgent = (params) => apiGET("/dashboard/api/deactivate_agent/", params);

export const uploadTrackingDetails = (body) => apiPOST("/pre_litigation/api/upload_tracking_details/", body);

export const generateWebNotice = (params) => apiGET("/pre_litigation/api/check_sample_web_notice/", params);

export const getFirStates = (params) => apiGET("/fir/api/get_states/", params);

export const getBatchesForAdmin = (params) => apiGET("/dashboard/api/get_batchs_for_admin/", params);

export const redistributeLeads = (params) => apiGET("/dashboard/api/redistribute_leads/", params);

export const redistributeLeadsToActiveAgents = (params) => apiGET("/dashboard/api/distributed_leads_to_active_agents/", params);

export const getAdminPanelClients = (params) => apiGET("/client_panel/api/get_client/", params);

export const createNewClient = (body) => apiPOST("/dashboard/api/client/", body);

export const updateClientDetails = (body) => apiPOST("/dashboard/api/client_new/", body);

export const bulkUpdateWatiAccount = (body) => apiPUT("/dashboard/api/wati_account_bulk_update/", body);

export const getRetentionPreparationReport = (params) => apiGET("/dashboard/api/retention_preparation_report/", params);

export const extractActualAllocationData = (body) => apiPOST("/pre_litigation/api/extract_actual_allocation_data/", body);

export const getDailyFeedBackReport = (params) => apiGET("/dashboard/api/daily_feedback_report/", params);
export const getMissingContactDetails = (client_id) => apiGET(`dashboard/api/alternate_number_missing_email/?client_id=${client_id}`);

// Manage Agents
export const getAdminAgents = (params) => apiGET("/dashboard/api/new_agents/", params);
export const postAdminAgents = (body) => apiPOST("/dashboard/api/new_agents/", body);
export const putAdminAgents = (body) => apiPOST("/dashboard/api/new_agents_new/", body);

export const getWhatsappLogs = () => apiGET("https://whatsapp.themedius.ai/scrapping/api/whatsapp/getLogs/");

export const moveTrackingToShipway = (params) => apiGET("/pre_litigation/api/move_tracking_to_shipway/", params);

export const uploadSmsReport = (body) => apiPOST("/dashboard/api/upload_sms_report/", body);

// file auto mapping
export const getFileMappings = (params = { client: null, type: null }) => apiGET("/dashboard/api/client_batch_mapping/", params);
export const createUpdateFileMappings = (body = { client: null, type: null, mapping: {} }) => apiPOST("/dashboard/api/client_batch_mapping/", body);

// sub-process & categories
// sub-process
export const getSubProcess = (params) => apiGET("/dashboard/api/subprocess/", params);
export const postSubProcess = (body) => apiPOST("/dashboard/api/subprocess_new/", body);
export const deleteSubProcess = (body) => apiPOST("/dashboard/api/subprocess_new/", body);
export const putSubProcess = (body) => apiPOST("/dashboard/api/subprocess_new/", body);
// sub-process - categories
export const getSubProcessCategory = (params) => apiGET("/dashboard/api/subprocess_category/", params);
export const postSubProcessCategory = (body) => apiPOST("/dashboard/api/subprocess_category/", body);
export const putSubProcessCategory = (body) => apiPOST("/dashboard/api/subprocess_category_new/", body);
export const agentAutoOutgoing = (body) => apiPOST("/dashboard/api/agent_outgoing/", body);

// MANAGEMENT REPORTS

// Settlements
export const customerSettlementManagementReport = (params) => apiGET("/dashboard/api/customer_settlement_management_report/", params);
export const clientSettlementManagementReport = (params) => apiGET("/dashboard/api/client_settlement_management_report/", params);
export const clientPreApproveedSettlementManagementReport = (params) => apiGET("/dashboard/api/client_pre_approved_settlement_management_report/", params);
export const agentSettlementManagementReport = (params) => apiGET("/dashboard/api/agent_settlement_management_report/", params);

// Agent Report
export const agentManagementReport = (params) => apiGET("/dashboard/api/agent_management_report/", params);
export const agentAbsentManagementReport = (params) => apiGET("/dashboard/api/agent_absent_management_report/", params);

// Campaign Status
export const getPendingIvrCampaignManagementReport = (params) => apiGET("/dashboard/api/pending_ivr_campaign_management_report/", params);
export const getIvrCampaignManagementReport = (params) => apiGET("/dashboard/api/ivr_campaign_management_report/", params);
export const getNoticeCampaignManagementReport = (params) => apiGET("/dashboard/api/notice_campaign_management_report/", params);
export const getPtpCampaignManagementReport = (params) => apiGET("/dashboard/api/ptp_campaign_management_report/", params);
export const getAlternateCampaignManagementReport = (params) => apiGET("/dashboard/api/alternate_campaign_management_report/", params);

// File Upload & Exophone (differ by param -> type)
export const getFileUploadStatus = (params) => apiGET("/dashboard/api/get_file_upload_status/", params);

// Collection Management Report
export const getCollectionManagementReport = (params) => apiGET("/dashboard/api/collection_management_report/", params);

// Legal Calling MIS
export const getLegalCallingMisSettlementReport = (params) => apiGET("/dashboard/api/legal_calling_mis_settlement_report/", params);

// Lead Report
export const getLeadManagementReport = (params) => apiGET("/dashboard/api/lead_management_report/", params);

// Report Generator
export const noticeCommunicationConnectivity = (params) => apiGET("/dashboard/api/notice_communication_connectivity/", params);

export const uploadSetlLetters = (body) => apiPOST("/dashboard/api/upload_setl_letters/", body);

export const todaysCampaignSummary = (params) => apiGET("/campaigns/api/todays_campaign_summary/", params);

export const testTodaysCampaigns = (params) => apiGET("/campaigns/api/test_todays_campaigns/", params);

export const getClientProducts = (params) => apiGET("/dashboard/api/get_client_products/", params);

export const getDailySettlementData = (params) => apiGET("/dashboard/api/get_daily_settlement_data/", params);

export const getEmailDailySettlementData = (params) => apiGET("/dashboard/api/email_daily_settlement_data/", params);

export const postAddAltContactInfo = (body) => apiPOST("/dashboard/api/add_alt_contact_info/", body);

// call quality summry api

export const getMonthlyCallQualitySummry = (params) =>
  apiGET(`call/api/get_call_quality_summary/?client=${params.client_id}&user_id=${params.user_id}&summary_type=monthly`);
export const getDayCallQualitySummry = (params) =>
  apiGET(
    `call/api/get_call_quality_summary/?client=${params.client_id}&user_id=${params.user_id}&from_date=${params.from_date}&to_date=${params.to_date}&summary_type=day`
  );
export const getAuditorCallQualitySummry = (params) =>
  apiGET(
    `call/api/get_call_quality_summary/?client=${params.client_id}&user_id=${params.user_id}&from_date=${params.from_date}&to_date=${params.to_date}&summary_type=auditor`
  );

export const getAgentReportSummary = (params) => apiGET(`call/api/agent_report/`, params);

export const getAttendaceSummaryDetailsReport = (params) => apiGET(`/dashboard/api/get_agent_attendance_insights/`, params);

export const getAuditorsListing = () => apiGET(`account/api/user_role_admin`);
export const getAgentListing = () => apiGET(`dashboard/api/agents?is_deactivated=False`);
